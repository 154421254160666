var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jd_score_com"},[_c('div',{staticClass:"operate"},[_c('i',{staticClass:"el-icon-circle-close",on:{"click":_vm.deleteAction}})]),_c('h3',[_vm._v(" "+_vm._s(_vm.item.zhoukao.split("-")[1])+"月第"+_vm._s(_vm.item.zhoukao.split("-")[2])+"周 ")]),_c('div',{staticClass:"score"},[_c('div',{class:{
        zhoukao: true,
        bjg: _vm.item.kaoshi < 60,
        jg: _vm.item.kaoshi >= 60 && _vm.item.kaoshi < 90,
        yx: _vm.item.kaoshi >= 90,
      }},[_vm._v(" 成绩:"+_vm._s(_vm.item.kaoshi)+" ")]),_c('div',{class:{
        bukao: true,
        bjg: _vm.item.bukao < 60 && _vm.item.bukao > 0,
        jg: _vm.item.bukao >= 60 && _vm.item.bukao < 90,
        yx: _vm.item.bukao >= 90,
      }},[_vm._v(" 补考:"+_vm._s(_vm.item.bukao)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }