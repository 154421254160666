<template>
  <div class="jdcj">
    <div class="mark">
        <!-- <i class="el-icon-circle-plus-outline" @click="show=!show"></i> -->
        <!-- <div class="add" v-if="show">
            <el-form  class="form" ref="form" :model="form" label-width="80px">
                <el-form-item label="学号">
                    <el-input v-model="form.stu_id"></el-input>
                </el-form-item>
                <el-form-item label="成绩">
                    <el-input v-model="form.kaoshi"></el-input>
                </el-form-item>
                <el-form-item label="考试日期">
                    <el-col :span="11">
                         <el-select v-model="form.month" placeholder="请选择月份">
                            <el-option label="1" value="1"></el-option>
                            <el-option label="2" value="2"></el-option>
                            <el-option label="3" value="3"></el-option>
                            <el-option label="4" value="4"></el-option>
                            <el-option label="5" value="5"></el-option>
                            <el-option label="6" value="6"></el-option>
                            <el-option label="7" value="7"></el-option>
                            <el-option label="8" value="8"></el-option>
                            <el-option label="9" value="9"></el-option>
                            <el-option label="10" value="10"></el-option>
                            <el-option label="11" value="11"></el-option>
                            <el-option label="12" value="12"></el-option>
                        </el-select>
                    </el-col>
                    <el-col class="line" :span="1">-</el-col>
                    <el-col :span="11">
                         <el-select v-model="form.day" placeholder="请选择周数">
                           <el-option label="1" value="1"></el-option>
                           <el-option label="2" value="2"></el-option>
                           <el-option label="3" value="3"></el-option>
                           <el-option label="4" value="4"></el-option>
                           <el-option label="5" value="5"></el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">添加</el-button>
                </el-form-item>
                </el-form>
        </div> -->
    </div>
    <ul class="stus">
      <li v-for="(item, index) in chengji" :key="index">
        <h3 class="stu_name">{{ item.stu_name }}</h3>
        <div
          class="scores"
          v-for="(item1, index1) in item.scores"
          :key="index + '-' + index1"
        >
          <JDScoreCom :item="item1"></JDScoreCom>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import JDScoreCom from "@/components/jdScore.vue";
export default {
  name: "JDScore",
  data() {
    return {
      chengji: [],
      show:false,
      form: {
          stu_id: '',
          kaoshi: '',
          month:null,
          day:null
      }
    };
  },
  components: {
    JDScoreCom,
  },
  async created() {
    let stus = [];
    //处理周考成绩
    let scores = this.$route.query.data.datas;
    for (let i in scores) {
      let item = scores[i];
      for (let j in item.t_scores) {
        let score = item.t_scores[j];
        let orExist = false;
        for (let k in stus) {
          if (stus[k].tel == score.stu.tel) {
            //此时已经有该学生
            stus[k].scores.push({
              zhoukao: item.zhoukao,
              kaoshi: score.kaoshi,
              bukao: score.bukao,
              id:score.id
            });
            orExist = true;
            break;
          }
        }
        if (orExist == false && score.stu.kind) {
          stus.push({
            stu_name: score.stu.stu_name,
            tel: score.stu.tel,
            stu_id:score.stu.stu_id,
            scores: [
              {
                zhoukao: item.zhoukao,
                kaoshi: score.kaoshi,
                bukao: score.bukao,
                id:score.id
              },
            ],
          });
        }
      }
    }
    this.chengji = stus;
    //获取所有的考试阶段
    let zhoukaos = [];
    for(let i in stus){
        zhoukaos = [...zhoukaos, ...stus[i].scores.map(item=>item.zhoukao)];
    } 
    zhoukaos = new Set(zhoukaos);
    zhoukaos = [...zhoukaos];
    //将缺失的学生数据进行补齐
    for(let i in stus){
        let item = stus[i];
        if(item.scores.length < zhoukaos.length){
            //此时该生存在需要补齐的成绩，此时查找该成绩需要添加的位置坐标
            for(let j in zhoukaos){
                let exist = false;
                for(let k in item.scores){
                    if(item.scores[k].zhoukao == zhoukaos[j]){
                        exist = true;
                        break;
                    }
                }
                //判断是否添加对应阶段的周考
                if(!exist){
                    this.$datas.meta = {stu_id:item.stu_id, kaoshi:"0.0", zhoukao:zhoukaos[j]};
                    let res3 = await this.$datas.addStuScoreById;
                    item.scores.push(res3.data);
                    item.scores.sort((item1, item2)=>{
                        return item1.zhoukao-item2.zhoukao;
                    });
                } 
            } 
        }
    }
  },
  methods:{
    //  async onSubmit() {
        
    //     // let res = await this.$datas.addStuScoreById;
    //     // if(res.status == 200){
    //     //     this.$message("成绩添加成功!");
    //     //     this.show = false;
    //     // }
    //   }
  }
};
</script>
<style lang="less" scoped>
.jdcj {
  .stus {
    li {
      display: flex;
      margin-top: 10px;
      padding: 0 10px;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #ccc;
      h3 {
        font-size: 14px;
        color: #555;
        width: 80px;
      }
      .scores {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .mark {
    i {
        color: #409eff;
        font-size: 25px;
        margin: 10px 20px;
        cursor: pointer;
    }
    .add {
         border-bottom: 1px dashed #ccc;
    }
    .form {
        width: 50%;
        margin-left: 50px;
    }
  }
}
</style>
