<template>
  <div class="jd_score_com">
    <div class="operate">
        <i class="el-icon-circle-close" @click="deleteAction"></i>
    </div>
    <h3>
      {{ item.zhoukao.split("-")[1] }}月第{{ item.zhoukao.split("-")[2] }}周
    </h3>
    <div class="score">
      <div
        :class="{
          zhoukao: true,
          bjg: item.kaoshi < 60,
          jg: item.kaoshi >= 60 && item.kaoshi < 90,
          yx: item.kaoshi >= 90,
        }"
      >
        成绩:{{ item.kaoshi }}
      </div>
      <div
        :class="{
          bukao: true,
          bjg: item.bukao < 60 && item.bukao > 0,
          jg: item.bukao >= 60 && item.bukao < 90,
          yx: item.bukao >= 90,
        }"
      >
        补考:{{ item.bukao }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JDScoreCom",
  props: ["item"],
  methods:{
    async deleteAction(){
        console.log(this.item);
        this.$datas.meta = {t_id:this.item.id};
        await this.$datas.deleteScoreById;
        this.$message('成绩已删除');
    }
  }
};
</script>
<style lang="less" scoped>
.jd_score_com {
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 10px 10px;
  position: relative;
  .operate {
    text-align: right;
    padding:2px 5px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    i {
        cursor: pointer;
        color: #409eff;
    }
  }
  h3 {
    font-size: 13px;
    font-weight: 400;
    color: #555;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
  }
  .score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: white;
    padding: 5px 10px;
    div {
      padding: 2px 5px;
      border-radius: 3px;
    }
  }
  .bjg {
    background-color: #f56c6c;
  }
  .jg {
    background-color: #409eff;
  }
  .yx {
    background-color: #fff;
    color: black;
  }
}
</style>
